@tailwind preflight;
@tailwind components;
@tailwind utilities;

/* @import "tailwindcss/preflight";
@import "tailwindcss/components";
@import "tailwindcss/utilities"; */

/**
* This injects Tailwind's base styles, which is a combination of
 * Normalize.css and some additional base styles.
 *
 * You can see the styles here:
 * https://github.com/tailwindcss/tailwindcss/blob/master/css/preflight.css
 *
 * If using `postcss-import`, use this import instead:
 *
 * @import "tailwindcss/preflight";
 */

/**
 * This injects any component classes registered by plugins.
 *
 * If using `postcss-import`, use this import instead:
 *
 * @import "tailwindcss/components";
 */

/**
 * Here you would add any of your custom component classes; stuff that you'd
 * want loaded *before* the utilities so that the utilities could still
 * override them.
 *
 * Example:
 *
 * .btn { ... }
 * .form-input { ... }
 *
 * Or if using a preprocessor or `postcss-import`:
 *
 * @import "components/buttons";
 * @import "components/forms";
 */

/**
 * This injects all of Tailwind's utility classes, generated based on your
 * config file.
 *
 * If using `postcss-import`, use this import instead:
 *
 * @import "tailwindcss/utilities";
 *
 */

/**
 * Here you would add any custom utilities you need that don't come out of the
 * box with Tailwind.
 *
 * Example :
 *
 * .bg-pattern-graph-paper { ... }
 * .skew-45 { ... }
 *
 * Or if using a preprocessor or `postcss-import`:
 *
 * @import "utilities/background-patterns";
 * @import "utilities/skew-transforms";
 */

/* shared stuff */
.headerLogo {
  @apply mt-4;
  margin-bottom: 0.8rem;
  width: 160px;
}
@screen md {
  .headerLogo {
    width: auto;
    height: 42px;
    width: 200px;
  }
}

.btn {
  @apply text-center text-2xl p-4 rounded-lg w-full mb-6 font-semibold;
}
.aBtn {
  @apply text-center text-2xl p-4 rounded-lg mb-6 font-semibold no-underline inline-block cursor-pointer;
}
.aBtn:focus,
.btn:focus {
  @apply outline-none;
}
.btn:disabled {
  @apply cursor-not-allowed;
}
.btn-teal {
  @apply bg-teal-light text-white border-2 border-teal-light;
}
.btn-teal:hover {
  @apply bg-teal border-teal;
}
.btn-teal:focus {
  @apply border-teal-lighter;
}
.btn-orange {
  @apply bg-orange-dark border-2 border-orange-dark text-white;
}
.btn-orange:hover {
  @apply bg-orange border-orange;
}
.btn-orange:disabled {
  @apply opacity-50;
}
.btn-orange:focus {
  @apply border-orange-darker;
}
.btn-yellow {
  @apply bg-yellow-dark border-2 border-yellow-dark text-white;
}
.btn-yellow:hover {
  @apply bg-yellow-darker border-yellow-darker;
}
.btn-yellow:focus {
  @apply border-yellow-darkest;
}
.btn-yellow__grey {
  @apply text-grey-darkest;
}
.btn-blue {
  @apply bg-blue border-2 border-blue text-white;
}
.btn-blue:hover {
  @apply bg-blue-light border-blue-light;
}
.btn-blue:focus {
  @apply border-grey-darker;
}

.btn-grey {
  @apply bg-grey border-2 border-grey text-white;
}
.btn-grey:hover {
  @apply bg-grey-darker border-grey-darker;
}
.btn-grey:focus {
  @apply border-grey-darkest;
}

.btn-grey-light {
  @apply bg-grey-light border-2 border-grey-light text-teal;
}
.btn-grey-light:hover {
  @apply bg-grey border-grey;
}
.btn-grey-light:focus {
  @apply border-grey-darker;
}

.btn-md {
  @apply text-center rounded-lg py-4 px-8 font-semibold;
}
.btn-sm {
  @apply text-center rounded p-2 font-semibold;
}
.btn-sm:focus,
.btn-md:focus {
  @apply outline-none;
}
.btn-sm-grey {
  @apply text-white bg-grey border-2 border-grey;
}
.btn-sm-grey:focus {
  @apply border-grey-darkest;
}
.btn-sm-grey:hover {
  @apply bg-grey-darker border-grey-darker;
}

.btn-article {
  @apply no-underline text-lg;
  width: 300px;
  color: white !important;
}

input:focus,
.textAreaWithTip:focus-within,
select:focus,
textarea:focus {
  @apply outline-none border-2 border-teal-light;
}

.bg-translucent {
  background-color: rgba(255, 255, 255, 0.5);
}
.dropdownControl {
  @apply inline-block text-teal-light font-bold text-4xl no-underline cursor-pointer;
}
.stretchedText {
  @apply mr-2;
  transform: scaleX(1.5);
  transform-origin: 0 0;
}
.dropdownControl:hover {
  @apply text-teal-lighter;
}

.leading-lax {
  line-height: 1.77;
}

input:disabled {
  @apply bg-grey-lightest;
}

@media screen and (min-width: 992px) {
  .lg\:leading-lax {
    line-height: 1.77;
  }
}

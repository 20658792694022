.mainContainer {
  @apply mx-auto bg-white;
  max-width: 1440px;
  min-height: calc(100vh - 698px);
}
@screen md {
  .mainContainer {
    min-height: calc(100vh - 256px);
  }
}

.mainHeader {
  margin: 0 16px;
}
@screen lg {
  .mainHeader {
    margin: 0 72px;
  }
}

.homeContainer {
  @apply bg-nearwhite;
  min-height: calc(100vh - 698px);
}
@screen md {
  .homeContainer {
    min-height: calc(100vh - 256px);
  }
}

.homeButton {
  @apply px-4 py-2 rounded-lg font-semibold no-underline;
}
.homeButton-white {
  @apply bg-white text-grey-darkest;
  font-size: 16px;
}
.homeButton-white:hover {
  @apply bg-grey-darkest text-white;
  box-shadow: 2px 2px 4px #59595ba3;
}
.homeButton-whiteSmall {
  @apply bg-white text-grey-darkest px-2 py-1;
  font-size: 16px;
}
.homeButton-whiteSmall:hover {
  @apply bg-grey-darkest text-white;
  box-shadow: 2px 2px 4px #59595ba3;
}
.homeButton-listings {
  @apply text-white;
  font-size: 16px;
}
.homeButton-listings:hover {
  @apply border-grey-darkest;
  box-shadow: 2px 2px 4px #59595ba3;
}
.homeButton-listings_re {
  @apply bg-teal-light border-teal-light;
}
.homeButton-listings_jobs {
  @apply bg-blue border-blue;
}
.homeButton-listings_services {
  @apply bg-purple-dark border-purple-dark;
}

.homeTopImage {
  padding-top: 94px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 75vh;
  background-image: url(../images/homepage/homepage-hero-room-cropped.jpg);
}
.homeTopCTAContainer {
  @apply h-full flex flex-col items-center justify-center;
}
.homeTopCTAText {
  @apply mx-auto text-nearwhite text-center font-semibold mb-12 pt-0;
  font-size: 40px;
  text-shadow: #5f5f5f 0px 3px 6px;
}

@screen md {
  .homeTopCTAText {
    font-size: 80px;
  }
}
.homeSectionContainer {
  margin-left: 16px;
}
@screen lg {
  .homeSectionContainer {
    margin-left: 72px;
  }
}

.footer {
  @apply mx-auto;
}
.footerMenusContainer {
  @apply bg-grey-dark flex flex-wrap p-8 pt-8;
}

.footerLogoContainer {
  @apply bg-grey-darker text-white;
  padding-left: 36px;
}
.h3_footer {
  @apply text-white mb-2 pb-2 border-b;
}
@screen md {
  .footer {
    @apply flex;
  }
  .footerLogoContainer {
    order: 1;
    width: 30%;
  }
  .footerMenusContainer {
    order: 2;
    width: 70%;
    @apply pl-16;
  }
}

.leading-lax {
  line-height: 1.77;
}

.cfulPage h2 {
  @apply mb-4;
}
.cfulPage p,
.p-text {
  @apply text-lg leading-lax mb-4;
}
.h2-text {
  @apply mb-2 mt-8 text-xl;
  color: config('colors.grey-darkest');
}
.li-text {
  @apply mb-8 leading-tight text-lg;
}
.cfulPage a,
.a-text {
  @apply text-teal-light no-underline;
}
.cfulPage a:hover,
.a-text:hover {
  @apply text-teal;
}

.cfulPage h1 {
  @apply text-purple;
}
.cfulPage-about a {
  @apply text-purple;
}
.cfulPage-about a:hover {
  @apply text-purple-dark;
}

.trim-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.userMenuList {
  @apply list-reset p-4 ml-4 text-xl text-teal-light;
}
@screen xl {
  .userMenuList {
    @apply p-0 pb-4 ml-0 text-sm;
  }
}
.accountMenuList {
  @apply list-reset p-0 ml-0 mb-8;
}
.accountMenuListItem {
  @apply pb-4;
}
.accountMenuListItem:last-child {
  @apply pb-0;
}

.accountMenuGreeting {
  @apply text-lg font-semibold mb-1;
}
.accountMenuMembershipLevel {
  @apply mb-2 text-sm uppercase font-bold;
  letter-spacing: 0.1em;
}
.accountMenuSubheader {
  @apply font-bold text-lg text-grey-darkest mt-2 mb-6 uppercase;
  letter-spacing: 0.1em;
  font-size: 14px;
}
.accountMenuLink {
  @apply no-underline text-teal-light text-base w-full;
}
.accountMenuLink:hover {
  @apply text-teal;
}

.userMenuLink {
  @apply no-underline text-teal-light inline-block w-full;
}
.userMenuLink:hover {
  @apply text-teal;
}
.signinJoinLink {
  @apply no-underline text-teal-light border border-teal-light px-2  py-1 rounded-lg;
  // @apply no-underline text-white bg-teal-light rounded-lg py-1 px-2 inline-block;
  // margin-top: -3px;
}
.signinJoinLink:hover {
  // @apply bg-teal;
  @apply text-teal border-teal;
}
.userMenuTop:hover {
  @apply text-teal;
}
.hr-userMenu {
  @apply border-b mt-4 mb-0;
}
.userMenuTop {
  @apply no-underline text-teal-light text-xl cursor-pointer px-8;
  background-repeat: no-repeat;
  background-position: right 0 top 0.2rem;
  background-size: 2rem 1.5rem;
  background-image: url('../images/newsletter/down-arrow-teal.svg');
}
@screen xl {
  .userMenuTop {
    @apply text-lg;
  }
}

.cookieNotice {
  @apply fixed bg-blue text-white rounded-l-lg;
  width: 300px;
  bottom: 100px;
  right: 0px;
  z-index: 101;
  box-shadow: 0px 3px 6px #0000004d;
}

.mobileBottomNav {
  @apply fixed z-50 border-t border-grey flex items-center justify-center;
  width: 100vw;
  height: 70px;
  bottom: 0;
  left: 0;
  background-color: #fffffff0;
}
.mobileBottomNav-show {
  height: 70px;
  transition: bottom 0.35s linear;
}
.mobileBottomNav-hidden {
  bottom: -70px;
  transition: bottom 0.35s linear;
}
@screen xl {
  .mobileBottomNav {
    display: none;
  }
}
.mobileBottomNavIcons {
  width: 100%;
  @apply flex items-center justify-center mx-auto;
}
@screen sm {
  .mobileBottomNavIcons {
    width: 80%;
  }
}
.mobileBottomNavIcon {
  @apply flex flex-col items-center justify-between text-center no-underline text-grey-darker;
  height: 45px;
  width: 25%;
  // border: 1px solid magenta;
}
.mobileBottomNavIcon:hover {
  @apply text-teal cursor-pointer;
}
.mobileBottomNavIcon-active {
  @apply text-teal-light;
}
.mobileBottomNavIconImg {
  enable-background: new 0 0 48 60;
  width: 20px;
}
@screen xs {
  .mobileBottomNavIconImg {
    width: 30px;
  }
}
.mobileBottomNavIconLabel {
  font-size: 0.6rem;
}
@screen sm {
  .mobileBottomNavIconLabel {
    @apply text-sm;
  }
}

.accountMenuContainer {
  @apply bg-white border border-grey-light;
  width: 100vw;
  z-index: 10;
  // box-shadow: 1px 1px 6px #bdbdbda3; //59595
  box-shadow: 0px 3px 6px #0000004d;
}
@screen xl {
  .accountMenuContainer {
    @apply absolute;
    z-index: 10;
    // top: 75px;
    // right: -76px;
    top: 55px;
    right: 0;
    width: 360px;
    clear: both;
  }
}

.userMenuContainer {
  @apply bg-white border border-grey-light;
  width: 100vw;
  z-index: 10;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 70px;
}
@screen xl {
  .userMenuContainer {
    @apply absolute;
    top: 78px;
    left: -220px;
    width: 220px;
  }
}
.redDot {
  @apply text-red absolute;
  top: -13px;
  font-size: 2rem;
}

.form_error {
  @apply text-red mt-2 text-sm;
}
.form_error:first-letter {
  text-transform: capitalize;
}
.field_with_errors input {
  @apply border-red border-2;
}

.accountHeader {
  @apply border-b-4 border-teal-light text-2xl mb-4 pb-2;
  margin-top: -20px;
}
@screen md {
  .accountHeader {
    @apply text-3xl;
  }
}

//
// Home page specific
//
.text-home {
  font-size: 20px;
}

.text-30 {
  font-size: 30px;
}
.homepageAboutContainer {
  @apply mx-auto max-w-2xl text-grey-darkest px-4 pb-16 bg-white;
  padding-top: 90px;
}
@screen md {
  .homepageAboutContainer {
    padding-top: 120px;
  }
}
@screen lg {
  .homepageAboutContainer {
    @apply px-0;
  }
}
.subscribeContainer {
  @apply mx-auto max-w-md text-lg px-4 text-grey-darkest;
  padding-top: 90px;
}
@screen lg {
  .subscribeContainer {
    @apply px-0;
  }
}
@screen md {
  .homepageAboutContainer,
  .subscribeContainer {
    padding-top: 120px;
  }
}
.resourceContainer {
  @apply mx-auto max-w-2xl text-grey-darkest px-4 pb-16 bg-white;
  padding-top: 190px;
}
.resourceFlushContainer {
  @apply mx-auto max-w-2xl text-grey-darkest px-4  pb-16 bg-white;
  padding-top: 145px;
}

@screen md {
  .resourceContainer {
    @apply px-0;
  }
  .resourceFlushContainer {
    @apply px-0;
  }
}
.wideContainer {
  @apply mx-auto max-w-4xl px-4;
}
.wideContainer-nopadding {
  @apply mx-auto max-w-4xl;
}
.topOffset {
  padding-top: 90px;
}
@screen md {
  .wideContainer,
  .wideContainer-nopadding {
    @apply px-12;
  }
}
@screen lg {
  .wideContainer {
  }
  .topOffset {
    padding-top: 120px;
  }
}
.homepageNormalContainer {
  @apply mx-auto max-w-2xl text-grey-darkest px-4  pb-16 bg-white;
  padding-top: 120px;
}
.homepageFlushContainer {
  @apply mx-auto max-w-2xl text-grey-darkest px-4   pb-16 bg-white;
}

.homepageSmallContainer {
  @apply mx-auto max-w-lg text-grey-darkest px-4  pb-16 bg-white;
  padding-top: 120px;
}
.homepageMediumContainer {
  @apply mx-auto max-w-xl text-grey-darkest px-4  pb-16 bg-white;
  padding-top: 120px;
}
.homepageNarrowContainer {
  @apply mx-auto max-w-md text-grey-darkest px-4  pb-16 bg-white;
  padding-top: 120px;
}
.homepageNarrowerContainer {
  @apply mx-auto max-w-md text-grey-darkest px-4  pb-16 bg-white;
  padding-top: 120px;
}
@screen md {
  .homepageNarrowerContainer {
    @apply px-20;
  }
}

//
// Press page specific
//
.featuredPressRowDivider {
  @apply border-t mt-8 pb-8;
}
.featuredPressRowDivider ~ .featuredPressRowDivider {
  @apply border-none;
  padding: 0;
  margin: 0;
}
.featuredPressBlock {
  @apply pb-4 border-r;
}
.featuredPressBlock:last-of-type {
  @apply border-none;
}
.featuredPressQuote {
  @apply italic leading-lax py-4 px-8;
}
.pressDetailsContainer {
  @apply leading-normal;
}
.pressImgContainer {
  @apply text-left;
}
.pressImgContainer img {
  height: 40px;
}
@screen lg {
  .pressDetailsContainer {
    @apply flex-auto ml-8;
  }
  .pressImgContainer {
    @apply text-center;
    flex: 0 0 200px;
  }
}

//
// How We Work
//
.hww-home-para {
  @apply leading-normal;
  font-size: 18px;
  max-width: 550px;
}
.hww-header {
  @apply text-teal-light text-xl mb-2;
}
.hww-para {
  @apply leading-tight text-base text-teal-light mb-6;
}
.hww-li {
  @apply leading-tight text-base text-teal-light mb-4;
  list-style-type: '✓ ';
}
.hww-block {
  @apply mb-12;
}
.hww-img {
  @apply mb-4 block mx-auto;
}
@screen lg {
  .hww-header {
    @apply text-2xl;
  }
  .hww-para {
    @apply text-lg leading-normal;
  }
  .hww-li {
    @apply text-lg;
  }
}
@screen lg {
  .hww-img {
    @apply inline-block;
  }
  .hww-2 {
    margin-top: 70%;
  }
  .hww-3 {
    margin-top: 75%;
  }
  .hww-4 {
    margin-top: 70%;
  }
  .hww-5 {
    margin-top: 130%;
  }
  .hww-6 {
    margin-top: 50%;
  }
  .hww-7 {
    margin-top: 100%;
  }
  .hww-8 {
    margin-top: 50%;
  }
  .hww-9 {
    margin-top: 80%;
  }
  .hww-10 {
    margin-top: 50%;
  }
}
.hww-link {
  @apply text-teal-light;
}
.hww-link:hover {
  @apply text-teal;
}

//
// About page
//

.aboutTopContainer {
  margin-left: 36px;

  width: 80%;
  max-width: 1200px;
}

@screen lg {
  .aboutTopContainer {
    margin-left: 72px;
    padding-top: 52px;
    padding-bottom: 52px;
  }
}
.aboutSectionContainer {
  margin: 0 16px;
}
@screen lg {
  .aboutSectionContainer {
    margin: 0 72px;
  }
}
.aboutTeamContainer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 100%);
  grid-gap: 0;
  justify-content: space-between;
}
@screen xl {
  .aboutTeamContainer {
    grid-template-columns: repeat(auto-fill, 31%);
    grid-gap: 2rem;
    justify-content: space-between;
  }
}
.aboutTeamHeader {
  @apply w-full bg-purple text-white font-bold text-center;
  font-size: 32px;
  height: 132px;
  padding: 35px 0 0 0;
}

.howItBeganRow {
  @apply flex flex-col;
  padding-bottom: 36px;
}
.howItBeganRow-left {
  width: 100%;
}
.howItBeganRow-right {
  width: 100%;
}

@screen md {
  .howItBeganRow {
    @apply flex-row;
    padding-bottom: 115px;
  }
  .howItBeganRow-left {
    width: 40%;
  }
  .howItBeganRow-right {
    width: 60%;
    padding-left: 10%;
    float: left;
  }
}

.about-bio p {
  @apply leading-normal mb-8 px-0 text-grey-darkest;
}
.about-bio a {
  @apply no-underline text-teal-light font-bold;
}
.about-bio a:hover {
  @apply text-teal;
}

.supportTeamContainer {
  @apply flex flex-wrap mx-auto;
}
.supportTeamContainer:after {
  content: '';
  flex: 300px 0 0;
  justify-content: space-between;
}
.supportTeamCard {
  flex: 0 50%;
  margin-bottom: 30px;
}
.supportTeamCard p {
  @apply pl-4;
}
@screen lg {
  .supportTeamCard {
    flex: 0 0 300px;
    width: 300px;
  }
  .supportTeamCard p {
    @apply pl-0;
  }
  .supportTeamContainer {
    max-width: 970px;
    justify-content: space-between;
  }
}

.aboutBragBlock {
  @apply bg-nearwhite;
  padding-bottom: 45px;
  padding-top: 36px;

  margin-left: 36px;
  margin-right: 36px;
}
.aboutBragContainer {
  @apply mx-auto  text-teal-light font-semibold flex flex-col justify-center text-center;
  font-size: 30px;
}
.aboutCenterBrag {
  @apply border-0;
}
.aboutBragMobileDivider {
  width: 72px;
  border-top: 1.5px solid #0f856d;
  line-height: 15px;
  font-size: 15px;
}
@screen lg {
  .aboutBragContainer {
    @apply flex-row;
    font-size: 36px;
  }
  .aboutBragBlock {
    padding-top: 70px;
    padding-bottom: 90px;
  }
  .aboutCenterBrag {
    @apply border-teal-light border-l border-r;
  }
  .aboutBragMobileDivider {
    display: none;
  }
}

.aboutBigText {
  @apply text-blue font-bold leading-tight;
  font-size: 24px;
}
.aboutBigText__top {
  margin-bottom: 36px;
}
@screen sm {
  .aboutBigText {
    font-size: 36px;
  }
  .aboutBigText__top {
    smargin-bottom: 48px;
  }
}

.aboutPhotoCaption {
  @apply uppercase text-blue font-bold pt-2;
  font-size: 12px;
  letter-spacing: 0.1em;
}
@screen sm {
  .aboutPhotoCaption {
    font-size: 14px;
  }
}

// Community Agreements Stuff, probably not used
.ca-card-container {
  @apply flex flex-wrap my-8;
}
.ca-card {
  @apply py-4;
  text-align: center;
  min-width: 100%;
  border: none;
}
.ca-card h3 {
  @apply text-purple-dark text-2xl mb-2;
  font-weight: semibold;
}
.ca-card p {
  @apply px-4 leading-normal text-lg;
  font-style: italic;
}

@screen md {
  .ca-card h3 {
    @apply text-3xl;
  }
  .ca-card:nth-child(1) {
    border-top: 1px solid config('colors.grey-light');
  }
  .ca-card:nth-child(2) {
    border-top: 1px solid config('colors.grey-light');
  }
  .ca-card:nth-child(odd) {
    border-right: 1px solid config('colors.grey-light');
  }
  .ca-card:nth-child(even) {
    border-left: none;
  }
  .ca-card {
    @apply py-8;
    min-width: 300px;
    max-width: 50%;
    border-bottom: 1px solid config('colors.grey-light');
    border-right: 1px solid config('colors.grey-light');
    border-left: 1px solid config('colors.grey-light');
  }
}

//
// Stories page
//
.featuredStory {
  @apply border-b-2 text-2xl text-center leading-lax py-16;
  background: url('../../assets/images/story_quotes.png') center center
    no-repeat;
  min-height: 86px;
  text-align: center;
}
.featuredStoryQuote {
}
@screen lg {
  .featuredStoryQuote {
    width: 650px;
    margin: 0 auto;
  }
}
.bold-link,
.standard-link {
  @apply no-underline text-teal-light;
}
.bold-link:hover,
.standard-link:hover {
  @apply text-teal;
}
.bold-link {
  font-weight: bold;
}

/* custom checkbox implementation via https://medium.com/claritydesignsystem/pure-css-accessible-checkboxes-and-radios-buttons-54063e759bb3 */
.customCheckbox input[type='checkbox'] {
  opacity: 0;
  height: 0;
  width: 0;
}
.customCheckbox label {
  position: relative;
  display: inline-block;
  /*16px width of fake checkbox + 6px distance between fake checkbox and text*/
  padding-left: 35px;
}
.customCheckbox label::before,
.customCheckbox label::after {
  position: absolute;
  content: '';
  /*Needed for the line-height to take effect*/
  display: inline-block;
}

.customCheckbox label::before {
  @apply rounded border border-grey;
  height: 24px;
  width: 24px;
  left: 0px;
  top: 0px;
}

.customCheckboxError label::before {
  border-color: config('colors.red');
}

/*Checkmark of the fake checkbox*/
.customCheckbox label::after {
  height: 7px;
  width: 12px;
  border-left: 2px solid config('colors.teal-light');
  border-bottom: 2px solid config('colors.teal-light');
  transform: rotate(-45deg);
  left: 6px;
  top: 6px;
}
/*Hide the checkmark by default*/
.customCheckbox input[type='checkbox'] + label::after {
  content: none;
}
/*Unhide on the checked state*/
.customCheckbox input[type='checkbox']:checked + label::after {
  content: '';
}
/*Adding focus styles on the outer-box of the fake checkbox*/
.customCheckbox input[type='checkbox']:focus + label::before {
  /*outline: rgb(59, 153, 252) auto 5px;*/
  @apply outline-none border-2 border-teal-light;
}

.customCheckboxLabel {
  line-height: 1.5;
}
// .customCheckboxLabel-aligned {
//   line-height: 1.5;
//   margin-left: -0.8rem;
// }
label.customCheckboxLabel-big {
  @apply font-bold text-2xl text-grey-darkest;
  line-height: 1;
  padding-left: 40px;
}

// will-paginate pagination
.pagination {
  @apply pl-0;
}
.pagination,
.pagination a {
  @apply text-teal text-lg no-underline;
}
.pagination a,
.pagination em.current {
  @apply px-2;
}
.pagination a:first-child {
  @apply pl-0;
}
.pagination a:hover {
  @apply text-teal-light;
}
.pagination em.current {
  font-style: normal;
  font-weight: bold;
}
.previous_page.disabled {
  display: none;
}

/* My Listings Nav */
.myListingsNav {
  @apply bg-white block w-full text-center border border-grey-darkest py-3 uppercase no-underline text-grey-darkest font-semibold text-base mb-4;
  letter-spacing: 0.05rem;
}
.myListingsNav__selected {
  @apply border-teal-light bg-teal-light text-white;
}
.myListingsNav__selected:hover {
  @apply border-teal bg-teal;
}
.myListingsNav__selected-jobs {
  @apply border-blue bg-blue text-white;
}
.myListingsNav__selected-jobs:hover {
  @apply border-blue-light bg-blue-light;
}
.myListingsNav__selected-services {
  @apply border-purple bg-purple text-white;
}
.myListingsNav-services__selected:hover {
  @apply border-purple-dark bg-purple-dark;
}

@screen md {
  .myListingsNav {
    @apply w-1/3 mb-0;
  }
  .myListingsNav__selected {
  }
}

/* Saved Listings */
.savedListingCardRoot {
  @apply mb-12;
  width: 100%;
}
.savedListingCardSpacer {
  background: none;
  display: none;
}
@screen sm {
  .savedListingCardRoot,
  .savedListingCardSpacer {
    width: 48%;
    display: block;
  }
}
@screen md {
  .savedListingCardRoot,
  .savedListingCardSpacer {
    width: 31%;
    display: block;
  }
}
@screen lg {
  .savedListingCardRoot,
  .savedListingCardSpacer {
    width: 23%;
    display: block;
  }
}
.dimmed {
  position: relative;
}

.dimmed:after {
  content: ' ';
  z-index: 10;
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: #8888887c;
  border-radius: 30px 30px 0 0;
  cursor: pointer;
}

.saveListingButton {
  @apply bg-nearwhite border cursor-pointer inline-flex items-center w-auto border-grey-darkest py-1 px-2 text-grey-darkest rounded-lg text-center text-base font-semibold no-underline;
  gap: 0.5rem;
}
.membershipLandingButton:hover {
  @apply bg-transparent;
}

.expiredListingButton {
  @apply bg-nearwhite uppercase tracking-wide rounded-lg border-2 cursor-pointer inline-flex items-center w-auto border-teal py-2 px-4 text-teal text-center text-sm font-semibold no-underline;
  gap: 0.5rem;
}
.expiredListingButton__notify {
  @apply border-grey-darkest text-grey-darkest;
}

.savedListingCard {
  @apply block no-underline border border-grey-darkest;
  border-radius: 30px;
  opacity: 1;
  box-shadow: 2px 2px 4px #4f4f4f;
  height: 100%;
}
.savedListingCard__re {
  box-shadow: 2px 2px 4px #10856d;
}
.savedListingCard__jobs {
  box-shadow: 2px 2px 4px #547886;
}
.savedListingCard__services {
  box-shadow: 2px 2px 4px #6e72b5;
}
.savedCardRegion {
  @apply text-sm inline-block bg-teal-light text-white font-semibold no-underline py-1 px-2 mt-6 ml-6;
}
.savedCardRegion__re {
  @apply bg-teal-light;
}
.savedCardRegion__jobs {
  @apply bg-blue;
}
.savedCardRegion__services {
  @apply bg-purple;
}
.savedCardImage {
  background: transparent 0% 0% no-repeat padding-box;
  background-position: center center;
  background-size: cover;
  border-radius: 30px 30px 0 0;
  aspect-ratio: 3/2;
}

.bookmarkTooltipContainer .bookmarkTooltip {
  @apply bg-nearwhite text-grey-darkest rounded-lg py-4 px-6;
  opacity: 1;
  max-width: 330px;
  box-shadow: 2px 2px 4px #4f4f4f;
  z-index: 10;
}

/* My Account stuff */
.listing-snippet-details-container {
}
.btn300 {
  width: 90%;
}
.myListingsPostButton {
  @apply inline-block no-underline;
  max-width: 300px;
}
@screen md {
  .myListingsPostButton {
    width: 300px;
  }
}

@screen md {
  .btn300 {
    width: 300px;
  }
  .listing-snippet-container {
    @apply flex;
  }
  .listing-snippet-details-container {
    @apply flex-grow pr-4;
  }
  .listing-snippet-controls-container {
    @apply flex-none text-center;
    width: 320px;
  }
}
.emptyImageContainer {
  background-color: #f1f5f8;
  position: relative;
  width: 100%;
  padding-top: 68%; /* aspect */
}
.emptyImageContents {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  font-size: 25px;
  color: white;
}

.status-arrow:before {
  content: '';
  position: absolute;
  border-top: 21px solid transparent;
  border-bottom: 21px solid transparent;
  right: -10px;
  top: 0;
}
.status-unreviewed:before {
  border-left: 10px solid #10d6ae;
}
.status-first:before {
  border-left: 10px solid #10d6ae;
  border-top: 21px solid #13a78a;
  border-bottom: 21px solid #13a78a;
  height: 100%;
}
.status-inreview:before {
  border-left: 10px solid #13a78a;
}
.status-second:before {
  border-left: 10px solid #13a78a;
  border-top: 21px solid #0f856d;
  border-bottom: 21px solid #0f856d;
}
.status-second-rejected:before {
  border-left: 10px solid #13a78a;
  border-top: 21px solid #e50707;
  border-bottom: 21px solid #e50707;
}
.status-second-notapproved:before {
  border-left: 10px solid #13a78a;
  border-top: 21px solid #7c82bf;
  border-bottom: 21px solid #7c82bf;
}

/* inquiries table */
div.rounded-table-container {
  border: 1px solid config('colors.grey');
  border-radius: 0.5rem;
  margin-bottom: 0.75rem;
}
.rounded-table-container table,
.inquiriesTable tbody,
.inquiriesTable thead {
  @apply text-lg;
  border: none;
}
.inquiriesTable thead {
  background: #fff;
  border-bottom: 1px solid config('colors.grey');
}
.inquiriesTable th {
  text-align: left;
}
.inquiriesTable tbody > tr:nth-child(4n-2),
.inquiriesTable tbody > tr:nth-child(4n-3),
.inquiriesTable tbody > tr:nth-child(4n-2) td,
.inquiriesTable tbody > tr:nth-child(4n-3) td {
  background-color: config('colors.grey-lighter');
}
.inquiriesTable tr:nth-child(4n-1) td {
  border-top: 1px solid config('colors.grey-lighter');
}
.inquiriesTable tr:nth-child(4n-3) td {
  border-top: 1px solid config('colors.grey-lighter');
}

.inquiriesTable tr td:nth-of-type(1n + 3),
.inquiriesTable tr th:nth-of-type(1n + 3) {
  @apply hidden;
}
@screen md {
  .inquiriesTable tr td:nth-of-type(1n + 3),
  .inquiriesTable tr th:nth-of-type(1n + 3) {
    @apply table-cell;
  }
}
.inquiryMessageHeader {
  color: config('colors.grey-darker');
  font-weight: normal;
  line-height: 1.5;
  margin-bottom: 1rem;
}
.inquiryMessageText p {
  color: config('colors.grey-darker');
  font-weight: normal;
  line-height: 1.5rem;
  margin-bottom: 1rem;
}

.inquiriesTable th,
.inquiriesTable td {
  line-height: 2;
  border-spacing: 0;
  border-collapse: collapse;
}
.inquiriesTable tbody > td {
  padding: 0 0 0.25rem 0.5rem;
}
.inquiriesTable td:first-child {
  padding-left: 0.5rem;
}
.inquiriesTable th:first-child {
  border-top-left-radius: 0.5rem;
  padding-left: 0.5rem;
}
.inquiriesTable th:last-child {
  border-top-right-radius: 0.5rem;
}
table.inquiriesTable {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  border: none;
}

a.inquiry-show::after {
  display: inline-block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: config('colors.teal-light') transparent transparent;
  right: 5px;
  margin-bottom: 1px;
  margin-left: 5px;
}
a.inquiry-hide::after {
  display: inline-block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-top-width: 0;
  border-bottom-style: solid;
  border-color: transparent transparent config('colors.teal-light');
  right: 5px;
  margin-bottom: 1px;
  margin-left: 5px;
}

/* resources/cms sections*/
.resources-view-all-link {
  @apply block relative mt-0 text-lg font-bold no-underline text-teal-light;
  margin-top: -2rem;
}
.resources-view-all-link:hover {
  @apply text-teal;
}
@screen md {
  .article-block-tandem {
    width: 32%;
  }
  .resources-view-all-link {
    @apply mt-8 text-lg font-bold no-underline text-teal-light;
  }
}
.resources-search-box {
  @apply border border-2 border-white rounded-full bg-teal-light text-white py-2 pl-6 pr-8;
}
.resources-search-box:focus {
  @apply border-white;
}
.resources-search-button {
  @apply absolute;
  background: url('../../assets/images/newsletter/MagnifyingGlass-white.png')
    no-repeat;
  width: 18px;
  height: 18px;
  top: 10px;
  right: 12px;
}

@screen md {
  .article-tandem-grid {
    @apply flex justify-between mt-8 flex-wrap;
  }
  .article-tandem-grid::after {
    content: '';
    flex: auto;
    flex: 0 0 32%;
  }
}

.article-cta-container {
  @apply bg-grey-lightest flex items-center flex-col px-2 py-8 mt-12;
}

.article-cta-text {
  @apply w-full text-center leading-normal font-semibold mb-4;
}
.article-cta-button-container {
  @apply w-full;
}
.article-button-container {
  @apply flex items-center flex-col;
}

.article-clipboard-link {
  background-image: url(../images/link-chain.png);
  background-repeat: no-repeat;
  padding-left: 24px;
  text-decoration: underline;
  color: config('colors.teal-light');
}
.article-clipboard-link:hover {
  @apply text-teal;
}

@screen md {
  .article-cta-container {
    @apply p-12 flex-row;
  }
  .article-cta-text {
    @apply mb-0;
  }
  .article-button-container {
    @apply flex-row justify-between;
  }
}
.full-width {
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100vw;
  position: relative;
  right: 50%;
  width: 100vw;
}

.article-body-image {
  @apply full-width;
}
@screen md {
  .article-body-image {
    width: 100%;
    margin: 0;
    max-width: 100%;
    position: static;
  }
}

.contentful-rich-text-wrapper p {
  @apply text-grey-darkest text-lg leading-lax mb-4;
}
.contentful-rich-text-wrapper .caption {
  @apply italic leading-tight text-sm;
}
.contentful-rich-text-wrapper a,
.contentful-wrapper-2 a {
  @apply text-teal-light;
}
.contentful-rich-text-wrapper a:hover,
.contentful-wrapper-2 a:hover {
  @apply text-teal;
}
.vote-body-wrapper p {
  @apply mb-4;
}
/* newsletter landing page */

.region-count {
  display: none;
}
.region-image-link {
  text-decoration: none;
  width: 100%;
  height: 100%;
  // width: 300px;
  // height: 169px;
  // text-align: center;
  @apply flex items-center justify-center text-center;
  //  vertical-align: middle;
}
@screen xs {
  .region-image-link {
    // width: 340px;
    // height: 192px;
  }
}
.region-image-link:hover {
  @apply rounded-lg;
  background-color: #4f4f4fa6;
}
.regionLandingWrapper {
  @apply flex items-center;
  width: 100%;
  aspect-ratio: 300/192;
}
@screen lg {
  .regionLandingWrapper {
    width: 48%;
  }
}
@screen xl {
  .regionLandingWrapper {
    width: 32%;
  }
}
.regionLandingContainer {
  @apply mb-8 rounded-lg;
  width: 100%;
  // width: 300px;
  // height: 192px;
  // width: 30%;
  aspect-ratio: 300/192;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
@screen xs {
  .regionLandingContainer {
    // width: 340px;
  }
}

.tape-landing {
  @apply block font-semibold text-xl text-white;
  box-shadow: 0 0 0 0.25rem #4f4f4fa6;
  background: #4f4f4fa6 0% 0% no-repeat padding-box;
  box-decoration-break: clone;
  line-height: 1.75;
  z-index: 30;
}
@screen xs {
  .tape-landing {
    @apply text-2xl;
  }
}
.landing-region-link-container {
  @apply text-teal-light font-bold text-2xl leading-loose;
}
.landing-region-link {
  @apply text-teal-light;
  // white-space: nowrap;
}
.landing-region-link:hover {
  @apply text-teal;
}
.landing-region-link__remote {
  @apply text-orange;
}
.landing-region-link__remote:hover {
  @apply text-orange-dark;
}

.subscribed-confirmation {
  background-color: #6e72b5;
  color: #fff;
  text-align: center;
  padding: 0.75rem 0;
  font-size: 15px;
  letter-spacing: 0.05rem;
  margin-top: -42px;
  margin-bottom: 42px;
}

/* super landing */
.section-nav {
  @apply flex flex-col justify-center items-center;
  max-width: 768px;
  margin: 0 auto;
}
.section-nav-item-container {
  @apply mb-8;
  width: 300px;
}
@screen md {
  .section-nav {
    @apply flex-row justify-between;
  }
  .section-nav-item-container {
    @apply mb-0;
  }
}

.landing-page-image-link {
  display: block;
  width: 95%;
  aspect-ratio: 1.18;
  background-position: center center;
  background-size: cover;
  border-radius: 20px;
  background-repeat: no-repeat;
}
.landing-page-image-link__squat {
  margin-bottom: 53px;
}
@screen md {
  .landing-page-image-link {
    min-width: 350px;
    aspect-ratio: 0.927;
  }
  .landing-page-image-link__tall {
    aspect-ratio: 0.89;
  }
  .landing-page-image-link__squat {
    aspect-ratio: 1.9;
    margin-bottom: 0;
  }
}

.section-nav-image-link {
  width: 300px;
  height: 225px;
  background-position: center center;
  background-size: cover;
  border-radius: 20px;
  background-repeat: no-repeat;
}
.section-nav-regions {
  color: #828282;
  margin: 12px 16px 0 16px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
}
// __withmembership
.regionIndexHeaderContainer__withMembership {
  margin-top: -50px;
}
@screen lg {
  .regionIndexHeaderContainer {
    margin-top: 0;
    @apply mx-auto max-w-4xl px-12;
  }
}
.firstAccessContainer {
  @apply overflow-hidden mb-4 w-full;
  height: 96px;
}
.firstAccessImage {
  height: 88px;
  position: relative;
  top: 0;
  left: 24px;
  z-index: 1;
  opacity: 0.99;
}
.firstAccessImage__header {
  @apply static float-left;
  height: 72px;
  margin-right: -1rem;
  z-index: 1;
  margin-right: -1.25rem;
  opacity: 0.99;
}
.firstAccessHeader {
  @apply w-auto block static float-left pl-6 text-3xl bg-teal-first text-teal-light inline-block font-semibold py-2 pr-3 mt-4;
  z-index: unset;
}
.firstAccessLabel {
  @apply w-full bg-teal-first text-teal-light inline-block font-semibold py-4 pl-24 pr-3 mt-4;
  position: relative;
  top: -60px;
  z-index: 0;
}
@screen md {
  .firstAccessContainer {
    @apply w-auto;
  }
}
@screen lg {
  .firstAccessContainer {
    @apply overflow-auto mb-0;
    height: auto;
  }
  .firstAccessImage {
    @apply static float-left;
    height: 72px;
    margin-right: -1rem;
    z-index: 1;
  }
  .firstAccessLabel {
    @apply w-auto block static float-left pl-6;
    z-index: unset;
  }
}

/* listing */
.newsletter-listing-index-image-container {
  width: 100%;
}
.newsletter-listing-index-image {
  width: 100%;
  aspect-ratio: 339/226;
}
@screen md {
  .newsletter-listing-index-image {
    width: 339px;
    height: 226px;
  }
  .newsletter-listing-index-image-container {
    width: 339px;
    height: 226px;
  }
}
.listing-meta-container {
  width: 100%;
}

@screen md {
  .listing-meta-container {
    width: 300px;
  }
}

/* jobs & gigs */
.job-labels-container,
.job-listing-meta-container {
  width: 100%;
}

@screen md {
  .job-labels-container {
    width: 300px;
  }
  .job-listing-meta-container {
    width: 300px;
  }
}
.job-type {
  @apply text-white bg-grey inline-block py-1 px-2 text-sm font-semibold mb-4 mr-2 inline-block;
}
@screen md {
  .job-type {
    @apply mb-1;
    margin-top: 5px;
  }
}
.job-type_lg {
  @apply py-1 px-2 block;
  font-size: 16px; //22px;
}

/* swiper style overrides */
@import 'swiper/css';
@import 'swiper/css/navigation';
@import 'swiper/css/pagination';
:root {
  --swiper-navigation-size: 44px;
  --swiper-navigation-top-offset: 0%;
  --swiper-navigation-sides-offset: 0;
  --swiper-navigation-color: #bdbdbd;
}

.swiper {
  z-index: unset;
}
.swiper-wrapper {
  z-index: unset;
}
.swiper-slide {
  box-sizing: border-box;
}
.swiper-slide:first-child {
  margin-left: 16px;
}
@screen lg {
  .swiper-slide:first-child {
    margin-left: 72px;
  }
}
.swiper-button-next,
.swiper-button-prev {
  height: 100%;
  width: 54px;
  // padding-right: 10px;
  // padding-left: 10px;
}
.swiper-button-next {
  margin-right: 10px;
}
.swiper-button-prev {
  margin-left: 10px;
}

// .swiper-button-next::after,
// .swiper-button-prev::after {
//   font-family: 'icomoon' !important;
//   speak: never;
//   font-style: normal;
//   font-weight: normal;
//   font-variant: normal;
//   text-transform: none;
//   line-height: 1;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   font-size: 30px;
//   padding: 10px;
//   border-radius: 50%;
//   border: 1px solid var(--swiper-navigation-color);
//   background-color: #fffffff0;
//   content: '\e901';
// }
.swiper-button-next::after,
.swiper-button-prev::after {
  background-image: url('../images/homepage/arrow-filled.svg');
  content: ' ';
  width: 43px;
  height: 43px;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  color: #828282;
}
.swiper-button-next::after {
  // box-shadow: 1px 1px 3px var(--swiper-navigation-color);
}
.swiper-button-prev::after {
  // box-shadow: -1px -1px 3px var(--swiper-navigation-color);
  transform: rotate(-180deg);
}
.swiper-button-disabled::after {
  border: none;
  box-shadow: none;
  color: transparent;
  background: none;
}
.swiper-button-next {
  // background: linear-gradient(
  //   90deg,
  //   rgba(255, 255, 255, 0) 0%,
  //   rgba(255, 255, 255, 1) 80%,
  //   rgba(255, 255, 255, 1) 100%
  // );
}
.swiper-button-prev {
  // background: linear-gradient(
  //   90deg,
  //   rgba(255, 255, 255, 1) 0%,
  //   rgba(255, 255, 255, 1) 20%,
  //   rgba(255, 255, 255, 0) 100%
  // );
}
.swiper-nav-custom {
  @apply text-center flex-none;
  width: 36px;
  height: 243px;
  display: grid;
  align-items: center;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
  cursor: pointer;
}
.swiper-nav-custom::after {
  font-family: swiper-icons;
  // font-size: var(--swiper-navigation-size);
  font-size: 22px;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
}

.swiper-nav-custom-prev::after {
  content: 'prev';
}
.swiper-nav-custom-next::after {
  content: 'next';
}
.swiper-button-disabled {
  @apply text-nearwhite;
  cursor: default;
}
@screen lg {
  .swiper-nav-custom {
    width: 72px;
  }
  .swiper-nav-custom::after {
    font-size: 44px;
  }
}

.homeLargeCard {
  @apply block no-underline flex-none  border border-grey-darkest;
  margin-right: 36px;
  width: 319px;
  height: 243px;
  border-radius: 30px;
  opacity: 1;
  box-shadow: 2px 2px 4px #10856d;
}
.homeLargeCard:hover {
  box-shadow: 4px 4px 4px #10856d;
}
.homeLargeCardHeadline {
  @apply text-grey-darkest mb-1 font-normal;
  font-size: 16px;
}
.homeLargeCardCategory {
  @apply font-bold text-grey-darkest mb-1;
  font-size: 16px;
}
.homeCardRegion {
  @apply inline-block bg-teal-light text-white font-semibold no-underline py-1 px-2 mt-6 ml-6;
  font-size: 14px;
}
.homeLargeCardImage {
  border-radius: 30px 30px 0px 0px;
}
.homeLargeCardImage__small {
  width: 318px;
  height: 152px;
  display: block;
}
.homeLargeCardImage__large {
  width: 497px;
  height: 319px;
  display: none;
}

@screen lg {
  .homeLargeCard {
    width: 498px;
    height: 464px;
  }
  .homeLargeCardHeadline {
    font-size: 20px;
  }
  .homeLargeCardCategory {
    font-size: 20px;
  }
  .homeCardRegion {
    // @apply mt-8 ml-8;
    font-size: 18px;
  }
  .homeLargeCardImage__small {
    display: none;
  }
  .homeLargeCardImage__large {
    display: block;
  }
}

.homeLargeCard__link {
  background-image: url('../images/homepage/balcony-640x427.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.homeSmallCard {
  @apply border border-grey-darkest bg-white py-6 pl-6 pr-6 no-underline flex flex-col justify-between flex-none;
  margin-right: 36px;
  width: 319px;
  height: 243px;
  border-radius: 30px;
  opacity: 1;
}
.homeSmallCardLink {
  @apply border border-grey-darkest  py-6 pl-6 pr-6 no-underline flex flex-col justify-between flex-none;
  margin-right: 36px;
  width: 319px;
  height: 243px;
  border-radius: 30px;
  opacity: 1;
}

.homeSmallCard__story {
  height: 315px;
}
.homeSmallCard__jobs {
  box-shadow: 2px 2px 4px #547886;
}
.homeSmallCard__jobs:hover {
  box-shadow: 4px 4px 4px #547886;
}
.homeSmallCard__jobs-link {
  background-image: url('../images/homepage/office2-640x427.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.homeSmallCard__services {
  box-shadow: 2px 2px 4px #6e72b5;
}
.homeSmallCard__services-link {
  background-image: url('../images/homepage/potter-640x487.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.homeSmallCard__services:hover {
  box-shadow: 4px 4px 4px #6e72b5;
}

.homeSectionCardContainer {
  @apply flex flex-col justify-center items-center;
}

.homeSectionCard {
  @apply flex flex-col flex-none no-underline justify-between bg-white mb-6;
  width: 90%;
  aspect-ratio: 1.2;
  border-radius: 30px;
  opacity: 1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.homeSectionCard:last-child {
  margin-right: 0;
}
.homeSectionCardTitle {
  @apply text-white font-bold mb-1;
  font-size: 24px;
}
@screen lg {
  .homeSectionCardContainer {
    @apply flex-row;
    padding: 0 72px;
  }
  .homeSectionCard {
    aspect-ratio: 0.7;
    max-height: 600px;
    width: 30%;
    margin-right: 36px;
  }
  .homeSectionCardTitle {
    font-size: 36px;
  }
}
.membershipSelectionContainer {
  @apply flex flex-col mb-0 text-grey-darkest;
  align-items: center;
}
.membershipSelectionCard {
  @apply cursor-pointer border border-grey-darkest bg-white py-6 pl-6 pr-6 no-underline;
  width: 319px;
  border-radius: 30px;
  box-shadow: 2px 2px 4px #10856d;
}
.selectionHeader {
  @apply text-xl mb-2 flex justify-between;
}
.membershipSelectionCard:hover {
  box-shadow: 4px 4px 4px #10856d;
}
.selectionPrice {
  @apply text-base font-semibold mb-4;
}
@screen lg {
  .membershipSelectionContainer {
    @apply flex-row mb-12;
    justify-content: stretch;
    align-items: stretch;
  }
  .membershipSelectionCard {
    @apply w-1/2 mb-0 py-8 pl-8 pr-8;
    margin-right: 36px;
  }
  .membershipSelectionCard:last-child {
    margin-right: 0px;
  }
  .selectionHeader {
    @apply text-2xl text-grey-darkest;
  }
  .selectionPrice {
    @apply text-lg;
  }
}

.membershipSelectionCard__supporting {
}
.membershipSelectionCard__free {
}
.membershipSelectionJoin {
  @apply text-center text-2xl p-4 border-2 border-grey-darkest text-grey-darkest rounded-lg font-semibold mx-auto mb-8;
  max-width: 300px;
}
.membershipSelectionJoin__active {
  @apply cursor-pointer  bg-teal-light text-white border-teal-light;
}
.membershipSelectionJoin__active:hover {
  @apply bg-teal;
}

.landingBenefitsContainer {
  @apply mx-auto mb-12 flex flex-col;
  max-width: 300px;
}
@screen lg {
  .landingBenefitsContainer {
    @apply flex-row;
    gap: 30px;
    max-width: unset;
  }
}

.landingBenefitImage {
  @apply mx-auto block;
}
.landingBenefitImage-browse {
  max-width: 65px;
}
.landingBenefitImage-card {
  max-width: 105px;
}
.landingBenefitImage-contact {
  max-width: 105px;
}
.landingBenefitImage-heart-hands {
  max-width: 115px;
}

@screen lg {
  .landingBenefitImage-browse {
    max-width: 80px;
  }
  .landingBenefitImage-card {
    max-width: 135px;
  }
  .landingBenefitImage-contact {
    max-width: 130px;
  }
  .landingBenefitImage-heart-hands {
    max-width: 125px;
  }
}

.h2-membershipLanding {
  @apply text-blue text-2xl mb-4;
}
.h3-membershipLanding {
  @apply text-blue text-lg text-center mb-1;
}
.p-membershipLanding {
  @apply text-blue text-base leading-tight;
}
.h3-membershipBennies {
  @apply text-blue text-xl text-center mb-1;
}
.p-membershipBennies {
  @apply text-blue text-base leading-tight;
}
.supporterFAQlist {
  @apply text-blue mb-2;
}
.supporterFAQlist li {
  @apply mb-1;
}
@screen lg {
  .h2-membershipLanding {
    @apply text-4xl;
  }
  .h3-membershipLanding {
  }
  .p-membershipLanding {
    @apply text-lg leading-normal;
  }
}
.membershipLandingButton {
  @apply bg-nearwhite border-2 inline-block border-grey-darkest py-2 px-8  text-grey-darkest rounded-lg text-center text-lg font-semibold no-underline;
}
.membershipLandingButton:hover {
  @apply bg-transparent;
}
.landingBenefitsImageWrapper {
  @apply mb-4;
}
@screen lg {
  .landingBenefitsImageWrapper {
    @apply flex items-end;
    height: 121px;
  }
}

.partnerImageBlock {
  @apply w-full;
}
.partnerImage {
  @apply mb-4;
  aspect-ratio: 170/123;
  object-fit: cover;
}
.partnerDescriptionBlock,
.voteDescriptionBlock {
  @apply flex flex-col w-full;
}
@screen md {
  .voteDescriptionBlock {
    @apply pr-8;
    min-width: 500px;
  }
}
@screen xl {
  .voteDescriptionBlock {
    min-width: 700px;
  }
}
.partnerHands {
  @apply mx-auto mb-4;
  width: 174px;
  height: 164px;
}
.partnerPartners {
  margin: 0 auto;
  max-width: 320px;
}

@screen lg {
  .partnerImageBlock {
    @apply flex-1;
    height: 340px;
    width: auto;
  }
  .partnerImage {
    height: 340px;
    width: 100%;
  }
  .partnerHands {
    width: 220px;
    height: 208px;
  }
  .partnerPartners {
    max-width: 780px;
  }
  .partnerDescriptionBlock {
    @apply justify-between;
    padding-right: 72px;
    min-width: 340px;
    width: 566px;
  }
}
@screen xxl {
  .partnerImageBlock {
    height: 434px;
  }
  .partnerImage {
    height: 434px;
  }
  .partnerDescriptionBlock {
    width: 723px;
    padding-right: 93px;
  }
}

.membershipLandingCard {
  @apply mb-0;
  position: relative;
  opacity: 0.99; /* workaround for z-index stacking order with top menu*/
}
@screen lg {
  .membershipLandingCard {
    @apply mb-6 flex-none;
    width: 30%;
  }
}
.checkmarkPurple {
  width: 20px;
  position: absolute;
  z-index: 1;
}
.checkmarkPurple_1 {
  left: -10px;
  top: -7px;
}
.checkmarkPurple_2 {
  left: -12px;
  top: -7px;
  transform: rotate(-15deg);
}
.checkmarkPurple_3 {
  left: -7px;
  top: -17px;
  transform: rotate(-10deg);
}
.checkmarkPurple_4 {
  left: -12px;
  top: -7px;
  transform: rotate(3deg);
}
@screen lg {
  .checkmarkPurple {
    width: 40px;
  }
  .checkmarkPurple_1 {
    left: -20px;
    top: -15px;
  }
  .checkmarkPurple_2 {
    left: -24px;
    top: -15px;
    transform: rotate(-15deg);
  }
  .checkmarkPurple_3 {
    left: -15px;
    top: -34px;
    transform: rotate(-10deg);
  }
  .checkmarkPurple_4 {
    left: -24px;
    top: -15px;
    transform: rotate(3deg);
  }
}
.selectionCheck {
  @apply text-xl font-normal border border-grey-darkest;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
}
.selectionCheck__selected {
  @apply border-teal-light bg-teal-light text-white;
  padding-left: 3px;
  padding-top: 1.5px;
}
.membershipSelectionList {
  @apply text-sm;
  list-style: inside;
  padding-left: 0;
}
.membershipSelectionItem {
  @apply leading-normal;
  list-style-type: '✓ ';
}
.selectionBenefitsHeader {
  @apply text-sm leading-tight mb-2 font-bold text-teal-light;
}
@screen lg {
  .membershipSelectionList {
    @apply text-base;
  }
  .selectionBenefitsHeader {
    @apply text-base;
  }
  .membershipSelectionItem {
    line-height: 1.75;
  }
  .membershipSelectionJoin {
  }
}

.homeSectionCardTop {
  background: linear-gradient(
    180deg,
    rgba(100, 100, 100, 0.7) 0%,
    rgba(52, 52, 52, 0.5) 60%,
    rgba(100, 100, 100, 0) 100%
  );
  border-radius: 30px 30px 0 0;
}
.homeSectionCard_re {
  box-shadow: 3px 3px 6px #10856d;
}
.homeSectionCard_jobs {
  box-shadow: 3px 3px 6px #547886;
}
.homeSectionCard_services {
  box-shadow: 3px 3px 6px #6e72b5;
}
.homeSectionCard_re:hover {
  box-shadow: 4px 4px 6px #10856d;
}
.homeSectionCard_jobs:hover {
  box-shadow: 4px 4px 6px #547886;
}
.homeSectionCard_services:hover {
  box-shadow: 4px 4px 6px #6e72b5;
}

.whatIsBigText {
  @apply text-blue font-bold leading-tight mb-8;
  font-size: 26px;
  max-width: 680px;
}

@screen sm {
  .whatIsBigText {
    @apply mb-20;
    font-size: 36px;
  }
}

.homeJoinTitle {
  @apply text-blue mb-6;
  font-size: 24px;
}
.homeJoinCheck {
  font-size: 18px;
  width: 1em;
  height: 0.5em;
  border-bottom: 2px solid #537886;
  border-left: 2px solid #537886;
  transform: rotate(-45deg);
}
.homeJoinItem {
  @apply ml-4 text-blue;
  font-size: 20px;
}
@screen md {
  .homeJoinTitle {
    font-size: 36px;
  }
  .homeJoinCheck {
    font-size: 20px;
  }
  .homeJoinItem {
    font-size: 20px;
  }
}

.homePressContainer {
  margin: 0 16px;
  padding-top: 90px;
  padding-bottom: 100px;
}

@screen lg {
  .homePressContainer {
    margin: 0 72px;
  }
  .homePressTop {
    @apply px-0;
    margin: 0 128px;
  }
}
.like-listing-promo {
  @apply flex flex-col justify-center border py-6 px-4 border-teal-light no-underline rounded-lg text-center text-blue;
  width: 250px;
  background-color: #ddeae8;
  box-shadow: 2px 2px 4px #537886a6;
}
.like-listing-promo:hover {
  box-shadow: 3px 3px 4px #537886a6;
}
.like-listing-container__jobs {
  @apply flex flex-col;
}
.like-listing__job:not(:last-child) {
  @apply pb-4 mb-4;
  // border-bottom: 1px solid #cacaca;
}
@screen md {
  .like-listing-container__jobs {
    @apply flex flex-row;
  }
  .like-listing {
    @apply w-1/4;
    border-bottom: none;
  }
  .like-listing__job:not(:last-child) {
    @apply mr-4;
    border-bottom: none;
  }
  .like-listing__job:not(:first-child) {
    @apply pl-4;
    // border-left: 1px solid #cacaca;
  }
}

.like-listing__services {
  max-width: 256px;
}
/* OG colors
:root {
  --jobs-ft: #0f0fff;
  --jobs-pt: #0e6711;
  --jobs-freelance: #229696;
  --jobs-internship: #9200b3;
  --jobs-remote: #b30029;
}
*/
:root {
  --jobs-ft: #6a7709;
  --jobs-pt: #5d009e;
  --jobs-freelance: #229696;
  --jobs-internship: #af2370;
  --jobs-remote: #b75207;
}
.job-type_full-time {
  background-color: var(--jobs-ft);
}
.job-type_part-time {
  background-color: var(--jobs-pt);
}
.job-type_freelance {
  background-color: var(--jobs-freelance);
}
.job-type_internship {
  background-color: var(--jobs-internship);
}
.job-type_remote {
  background-color: var(--jobs-remote);
}
.remote-friendly {
  width: 100%;
  @apply font-semibold inline-block;
  color: var(--jobs-remote);
}
.services_remote {
  background-color: var(--jobs-internship);
}
.remote-friendly__services {
  color: var(--jobs-internship);
}

.job-filter {
  cursor: pointer;
  @apply border-2 rounded-full py-1 px-2 text-sm no-underline font-semibold mr-2;
}
.job-filter:hover {
  @apply text-white;
}

.job-filter_full-time {
  color: var(--jobs-ft);
  border-color: var(--jobs-ft);
}
.job-filter_full-time--selected {
  background-color: var(--jobs-ft);
  @apply text-white;
}
.job-filter_full-time:hover {
  background-color: var(--jobs-ft);
}

.job-filter_part-time {
  color: var(--jobs-pt);
  border-color: var(--jobs-pt);
}
.job-filter_part-time--selected {
  background-color: var(--jobs-pt);
  @apply text-white;
}
.job-filter_part-time:hover {
  background-color: var(--jobs-pt);
}

.job-filter_internship {
  color: var(--jobs-internship);
  border-color: var(--jobs-internship);
}
.job-filter_internship:hover {
  background-color: var(--jobs-internship);
}
.job-filter_internship--selected {
  background-color: var(--jobs-internship);
  @apply text-white;
}

.job-filter_freelance {
  color: var(--jobs-freelance);
  border-color: var(--jobs-freelance);
}
.job-filter_freelance--selected {
  background-color: var(--jobs-freelance);
  @apply text-white;
}
.job-filter_freelance:hover {
  background-color: var(--jobs-freelance);
}

.job-filter_remote {
  @apply ml-0;
  color: var(--jobs-remote);
  border-color: var(--jobs-remote);
}
@screen md {
  .job-filter_remote {
    @apply ml-2;
  }
}
.job-filter_remote--selected {
  background-color: var(--jobs-remote);
  @apply text-white;
}
.job-filter_remote:hover {
  background-color: var(--jobs-remote);
}

.services-filter {
  cursor: pointer;
  @apply border-2 rounded-full py-1 px-2 text-sm no-underline font-semibold mr-2;
}
.services-filter:hover {
  @apply text-white;
}
.services-filter_remote {
  @apply ml-0;
  color: var(--jobs-internship);
  border-color: var(--jobs-internship);
}
.services-remote-button {
  @apply inline-block text-white text-sm font-semibold py-1 px-2;
  background-color: var(--jobs-internship);
  border-color: var(--jobs-internship);
}
@screen md {
  .services-filter_remote {
    @apply ml-2;
  }
}
.services-filter_remote--selected {
  @apply text-white;
  background-color: var(--jobs-internship);
}
.services-filter_remote:hover {
  background-color: var(--jobs-internship);
}

.services-index-image-container {
  width: 100%;
  height: auto;
}
.services-index-image-link {
  display: block;
  width: 100%;
}
.services-index-image {
  width: 100%;
  height: auto;
}
@screen md {
  .services-index-image-container {
    width: 270px;
    height: 200px;
  }
}

.services-left-col {
  @apply flex-none mr-8;
  width: 100%;
}
@screen lg {
  .services-left-col {
    width: 420px;
  }
}
.search-button-container {
  float: right;
  height: 38px;
}
.search-input-container {
  overflow: auto;
}
.search-button {
  width: 3rem;
  height: 38px;
  @apply bg-orange border border-grey border-l-0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: pointer;
}
.search-button__search {
  background: url('../images/newsletter/MagnifyingGlass-white.png') 50% 50%
    no-repeat #e87f39;
}
.search-button__clear {
  background: url('../images/newsletter/X-white.png') 50% 50% no-repeat #e87f39;
}

.newsletterFilterContainer {
  @apply flex flex-col mb-2;
}
@screen xl {
  .newsletterFilterContainer {
    @apply flex-row;
    gap: 1rem;
  }
}
.newsletter-dropdown-button {
  @apply block w-full text-left text-smish text-grey-darkest appearance-none w-full bg-white border border-grey px-4 py-2 pr-8 rounded leading-tight;
}
.newsletter-dropdown-button:hover {
  @apply border-grey-dark;
}
.newsletter-dropdown-button:focus {
  @apply outline-none shadow-outline;
}
@screen md {
  .newsletter-dropdown-button {
    min-width: 16.5rem;
  }
}
.text-smish {
  font-size: 16px;
}
.text-listing {
  font-size: 1.125rem;
  line-height: 1.77;
}
.text-listing p {
  margin-bottom: 1rem;
  word-break: break-word;
  overflow-wrap: break-word;
}
.text-listing h2 {
  @apply leading-tight;
}
.text-listing h3 {
  text-transform: uppercase;
  font-size: 1.125rem;
}
.text-listing a,
.text-listing span.blurred-contact_linked {
  @apply text-teal-light font-semibold no-underline;
}
text-listing a:hover {
  @apply text-teal;
}
.lock {
  width: 20pt;
  height: 20pt;
}
.lock path {
  transform: scale(0.019);
  fill: #0f856d; // teal-light
}
.text-listing__jobs .lock path {
  fill: #537886; // blue
}
.text-listing__services .lock path {
  fill: #6e72b5; //purple
}

.text-listing__jobs a,
.text-listing__jobs span.blurred-contact_linked {
  @apply text-blue font-semibold no-underline;
}
.text-listing__jobs a:hover {
  @apply text-blue-light;
}
div.text-listing__services {
}
.text-listing__services a,
.text-listing__services span.blurred-contact_linked {
  @apply text-purple font-semibold no-underline;
}
.text-listing__services a:hover {
  @apply text-purple-dark;
}

.copy-button {
  cursor: pointer;
  @apply text-white bg-teal-light border-teal-light border-2 text-sm px-1 ml-2 rounded no-underline;
}
.copied-button {
  @apply bg-white text-teal-light border-teal-light border-2 text-sm px-1 ml-2 rounded no-underline;
}

.blurred-contact {
  user-select: none;
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.text-listing__services {
}
.text-listing__jobs {
}
.contact__a {
  font-weight: 600;
  word-break: break-all;
}
a.ad-explainer-link,
.ad-explainer-link {
  color: #bdbdbd;
  font-weight: normal;
  text-decoration: underline;
  font-size: 1rem;
}
a.ad-explainer-link:hover,
.ad-explainer-link:hover {
  color: #dae1e7;
}

.btn__view-photos {
  @apply text-white font-semibold bg-teal-light no-underline px-8 py-4 rounded;
  position: absolute;
  right: 60px;
  bottom: 20px;
  margin: 0;
  display: none;
}
.btn__view-photos:hover {
  @apply bg-teal;
}
.btn__view-photos--jobs {
  @apply bg-blue;
}
.btn__view-photos--jobs:hover {
  @apply bg-blue-light;
}
.btn__view-photos--services {
  @apply bg-purple;
  display: block;
  position: absolute;
  right: 30px;
}
.btn__view-photos--services:hover {
  @apply bg-purple-dark;
}

@import 'blueimp-gallery/css/blueimp-gallery.css';
#lightbox-links {
  width: 50000px;
  height: 410px;
}
#lightbox-links img {
  height: 410px;
}
#lightbox-links a {
  margin-right: 0.5rem;
  display: block;
  float: left;
}
/* image gallery overrides */
.blueimp-gallery {
  background: rgba(2, 2, 2, 0.9);
}

.blueimp-gallery > .slides > .slide-loading,
.blueimp-gallery > .play-pause,
body:last-child .blueimp-gallery > .play-pause {
  background: none;
}

.blueimp-gallery > .close {
  background: url('../images/newsletter/modal-round-x-40.png') no-repeat;
  width: 40px;
  height: 40px;
  margin: 0;
}

.blueimp-gallery-controls > .prev,
.blueimp-gallery-controls > .next {
  width: 40px;
  height: 40px;
  border: none;
  opacity: 0.8;
  padding: 0;
}

.blueimp-gallery-controls > .prev {
  background: url('../images/newsletter/modal-left-arrow-40.png') no-repeat;
}

.blueimp-gallery-controls > .next {
  background: url('../images/newsletter/modal-right-arrow-40.png') no-repeat;
}

.blueimp-gallery > .slides > .slide-loading {
  background: url('../images/loading.gif') center no-repeat;
  background-size: 64px 64px;
}
// this forces images into the manifest; not sure how to make that happen w/ image_pack_tag automatically
.unused {
  background-image: url(../images/homepage/lp-devices-2.png);
  background-image: url(../images/logo-boxed.png);
  background-image: url(../images/logo-white.png);
  background-image: url(../images/homepage/instagram.svg);
  background-image: url(../images/press/buzzfeed.svg);
  background-image: url(../images/press/new-york.svg);
  background-image: url(../images/press/timeout.png);
  background-image: url(../images/press/curbed.png);
  background-image: url(../images/press/new-york-times.png);
  background-image: url(../images/press/artfcity-logo.png);
  background-image: url(../images/press/brick-underground-logo.png);
  background-image: url(../images/press/Vice.png);
  background-image: url(../images/press/Vogue.png);
  background-image: url(../images/press/Hyperallergic.png);

  background-image: url(../images/icons/calendar-black.svg);
  background-image: url(../images/icons/clock-black.svg);
  background-image: url(../images/icons/eye-black.svg);
  background-image: url(../images/icons/pencil-black.svg);
  background-image: url(../images/icons/check-black.svg);
  background-image: url(../images/icons/envelope-black.svg);
  background-image: url(../images/icons/form-black.svg);
  background-image: url(../images/icons/word-bubbles-black.svg);

  background-image: url(../images/homepage/LP-team-may-2023.jpg);
  background-image: url(../images/link-chain.png);
}
